import { NextPage } from 'next'
import dynamic from 'next/dynamic'
import { serverSideTranslations } from 'next-i18next/serverSideTranslations'
import React from 'react'

import { getFloatingMenu } from '@/services/apps'
import {
  getHomeAbout,
  getHomeBanner,
  getHomeBusinessOpportunity,
  getHomeGlimpse,
  getHomeIncentive,
  getHomeInvestors,
  getHomeMoreIncentive,
} from '@/services/home'
import { getNews } from '@/services/news'
import { wrapper } from '@/store'

const Home = dynamic(() => import('./Home'), { ssr: false })

const Page: NextPage = () => {
  return (
    <main className='min-h-screen bg-gray-100'>
      <Home />
    </main>
  )
}

export const getProps = wrapper.getServerSideProps((store) => async ({ locale }) => {
  const paramsList = {
    params: { skip: 0, limit: 1000 },
    headers: { 'Accept-Language': String(locale) },
  }
  await Promise.all([
    store.dispatch(getHomeBanner.initiate(paramsList)),
    store.dispatch(getHomeAbout.initiate({ headers: { ...paramsList.headers } })),
    store.dispatch(getHomeGlimpse.initiate({ headers: { ...paramsList.headers } })),
    store.dispatch(getHomeIncentive.initiate({ headers: { ...paramsList.headers } })),
    store.dispatch(getHomeMoreIncentive.initiate({ headers: { ...paramsList.headers } })),
    store.dispatch(getHomeMoreIncentive.initiate({ headers: { ...paramsList.headers } })),
    store.dispatch(getHomeBusinessOpportunity.initiate(paramsList)),
    store.dispatch(getNews.initiate({ ...paramsList, params: { ...paramsList.params, limit: 3 } })),
    store.dispatch(getHomeInvestors.initiate(paramsList)),
    store.dispatch(getFloatingMenu.initiate({ headers: { ...paramsList.headers } })),
  ])

  return {
    props: {
      ...(await serverSideTranslations(String(locale), ['common', 'home'])),
    },
  }
})

export default Page
